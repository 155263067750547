export const searchTypesId = {
    articleNumber: 0,
    oeNumber: 1,
    tradeNumber: 2,
    comparableNumber: 3,
    replacementNumber: 4,
    replacedNumber: 5,
    eanNumber: 6,
    criteriaNumber: 7,
    partsListChildArticleNumber: 8,
    accessoryListChildArticleNumber: 9,
    anyNumber: 10,
    freeText: 99,
    shop: 999
}

export const termCategoriesId = {
    disponibilities: 1
}

export const termSubCategoriesId = {
    available: 1,
    availableSoon: 2,
    availableDepot: 3
}
export const EstimateContentType = {
    product: 1,
    laborTime: 2,
    variousTimes: 3,
    Ingredients: 4,
    plan: 5
}